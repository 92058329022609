import { app, useFirebaseEmulator } from "@/plugins/firebase-app";
import { getAuth, onAuthStateChanged, connectAuthEmulator } from "firebase/auth";

import store from "@/store";

export const auth = getAuth(app)

onAuthStateChanged(auth, (user) => {
  return store.dispatch('Auth/setUser', user)
})

if (useFirebaseEmulator) {
  connectAuthEmulator(auth, process.env.VUE_APP_FIREBASE_EMULATOR_AUTH_BASE_URL, {
    disableWarnings: true
  })
}

export default auth;