const state = () => ({
  isNavigationDrawerOpen: false
})

const getters = {

}

const mutations = {
  SET_NAVIGATION_DRAWER_IS_OPEN_STATE(state, isOpen) {
    state.isNavigationDrawerOpen = isOpen
  }
}

const actions = {
  toggleNavigationDrawer({ state, commit }, isOpen) {
    commit('SET_NAVIGATION_DRAWER_IS_OPEN_STATE', isOpen ?? !state.isNavigationDrawerOpen)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}