import algoliaAccess from "@/api/modules/algolia-access";
import records from "@/api/modules/records";
import tables from "@/api/modules/tables";

import openai from "@/api/modules/openai";

export default {
  algoliaAccess,
  records,
  tables,

  openai
}