export const FieldTypes = Object.freeze({
  AUTONUM: "autonum",

  STRING: "string",
  NUMBER: "number",
  SELECT: "select",
  DATE: "date",
  AUDIOGRAM: "audiogram"
})

export default FieldTypes