import api from "@/api";
import Record from "@/models/Record";
import { RECORDS_COUNT } from "@/config/table";

const state = () => ({
  /**
   * Key is a `tableId`. Value is an `Array` of `Record`
   * @type {Object<String, Array<Record>>}
   */
  records: {}
})

const getters = {
  getTableRecords: (state) => (tableId) => state.records[tableId]
}

const mutations = {
  SET_TABLE_RECORDS(state, { tableId, records }) {
    state.records = Object.assign({}, state.records, {
      [tableId]: records
    })
  },
  APPEND_TABLE_RECORDS(state, { tableId, records }) {
    state.records[tableId] = [
      ...state.records[tableId],
      ...records
    ]
  },
  ADD_TABLE_RECORD(state, { tableId, record }) {
    if (state.records[tableId]) {
      state.records[tableId].unshift(record)
    } else {
      state.records[tableId] = [record]
    }
  },
  EDIT_TABLE_RECORD(state, { tableId, recordId, values }) {
    const record = state.records[tableId]?.find(({ id }) => id === recordId)

    if (record) {
      for (let fieldId in values) {
        record.values[fieldId] = values[fieldId]
      }
    }
  },
  DELETE_TABLE_RECORD(state, { tableId, recordId }) {
    if (state.records[tableId]) {
      state.records[tableId] = state.records[tableId].filter(({ id }) => id !== recordId)
    }
  }
}

const actions = {
  initTableRecords({ getters, dispatch }, { tableId }) {
    if (!getters.getTableRecords(tableId)) {
      dispatch('downloadTableRecords', { tableId })
    }
  },
  downloadTableRecords({ commit }, { tableId, startAfterId }) {
    return api.records.getTableRecords({
      tableId,
      startAfterId,
      itemsCount: RECORDS_COUNT
    }).then(({ data }) => {
      let records = data.records.map((record) => new Record(record))

      if (startAfterId) {
        commit('APPEND_TABLE_RECORDS', {
          tableId,
          records
        })
      } else {
        commit('SET_TABLE_RECORDS', {
          tableId,
          records
        })
      }

      return records
    })
  },
  loadMoreTableRecords({ dispatch, getters }, { tableId }) {
    return dispatch('downloadTableRecords', {
      tableId,
      startAfterId: getters.getTableRecords(tableId)?.at(-1)?.id
    })
  },
  createRecord({ dispatch, commit }, { tableId, values }) {
    return api.records.createRecord({
      tableId,
      values
    }).then(({ data }) => {
      commit('ADD_TABLE_RECORD', {
        tableId,
        record: new Record(data)
      })
      dispatch('Tables/incrementTableRecordsCount', tableId, { root: true })

      return data
    })
  },
  editRecord({ commit }, { tableId, recordId, values }) {
    return api.records.editRecord({
      tableId,
      recordId,
      values
    }).then(({ data }) => {
      commit('EDIT_TABLE_RECORD', {
        tableId,
        recordId,
        values
      })

      return data
    })
  },
  deleteRecord({ dispatch, commit }, { tableId, recordId }) {
    return api.records.deleteRecord({
      tableId,
      recordId
    }).then(() => {
      commit('DELETE_TABLE_RECORD', {
        tableId,
        recordId
      })
      dispatch('Tables/decrementTableRecordsCount', tableId, { root: true })

      return true
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}