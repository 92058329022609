import axios from "axios";
import { useFirebaseEmulator } from "@/plugins/firebase-app";

import authInterceptor from "@/api/interceptors/auth.interceptor";

export const ApiClient = axios.create({
  baseURL: useFirebaseEmulator
    ? process.env.VUE_APP_FIREBASE_EMULATOR_API_BASE_URL
    : process.env.VUE_APP_API_BASE_URL,
})

ApiClient.interceptors.request.use(authInterceptor)

export default ApiClient