import { createApp } from 'vue';
import App from './App.vue';

// import plugins
import router from "@/router";
import store from "@/store";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import api from "@/api";

// import libraries
import Toast, { useToast } from "vue-toastification";
import VueDatePicker from '@vuepic/vue-datepicker';

// import mixins
import debugMixin from '@/mixins/debug'

// import configs
import ToastConfig from "@/config/vue-toastification";

// import styles
import '@mdi/font/css/materialdesignicons.css'; // material design icons set
import 'vuetify/styles'; // vuetify styles
import '@/assets/styles/index.scss'; // app styles
import "@/assets/styles/libs/vue-toastification.scss"; // vue-toastification styles
import "@/assets/styles/libs/vue-draggable.scss"; // vue-draggable styles
import '@vuepic/vue-datepicker/dist/main.css' // vue-datepicker styles

const app = createApp(App)

// installation plugins
app.use(router)
app.use(store)
app.use(i18n)
app.use(vuetify)

// installation libraries
app.use(Toast, ToastConfig)
app.component('VueDatePicker', VueDatePicker)

// installation mixins
if (process.env.NODE_ENV !== 'production') {
  app.mixin(debugMixin)
}

app.config.globalProperties.$api = api
app.config.globalProperties.$toast = useToast()

// TODO: Remove it after vue 3.3 (ref: https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity)
app.config.unwrapInjectedRef = true

app.mount('#app')
