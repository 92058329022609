import FIELD_TYPES from "@/config/field-types";

export default class Field {
  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.order = data.order
    this.config = data.config
  }

  get isAutonum() {
    return this.type === FIELD_TYPES.AUTONUM
  }
  get isString() {
    return this.type === FIELD_TYPES.STRING
  }
  get isNumber() {
    return this.type === FIELD_TYPES.NUMBER
  }
  get isSelect() {
    return this.type === FIELD_TYPES.SELECT
  }
  get isDate() {
    return this.type === FIELD_TYPES.DATE
  }
}