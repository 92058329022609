import axios from "axios";

const TEST_API_KEY = process.env.VUE_APP_OPENAI_API_KEY

export const OpenAIApiClient = axios.create({
  baseURL: "https://api.openai.com",
  headers: {
    "Authorization": `Bearer ${ TEST_API_KEY }`
  }
})

export default OpenAIApiClient