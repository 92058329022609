import ApiClient from "@/api/api-client";

export default {
  /**
   *
   * @param {string} tableId - The ID of the table
   *
   * @return {Promise<axios.AxiosResponse<any>>} - A promise that resolves to the Axios response containing the API key.
   */
  getTableRecordsApiKey(tableId) {
    return ApiClient.post("/algoliaAccess/getTableRecordsApiKey", {
      tableId
    })
  }
}