import { createI18n } from "vue-i18n";

import en from "@/localizations/en.json";
import ua from "@/localizations/ua.json";

import { getLocalStorageValue } from "@/helpers/localStorage";
import localStorageKeys from "@/config/localStorageKeys";
import { DEFAULT_LOCALE } from "@/config/localization";

const locale = getLocalStorageValue(localStorageKeys.LOCALIZATION) || DEFAULT_LOCALE

const i18n = createI18n({
  locale,
  fallbackLocale: 'en',
  messages: {
    en,
    ua
  }
})

export default i18n