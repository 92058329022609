export const DEFAULT_LOCALE = 'ua';

export const LOCALIZATION_FLAGS = {
  'en': 'uk',
  'ua': 'ua'
};

export default {
  DEFAULT_LOCALE,
  LOCALIZATION_FLAGS
}