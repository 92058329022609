<template>
  <v-app-bar color="primary"
             class="header"
  >
    <template v-if="isLoggedIn && $vuetify.display.mdAndDown && false"
              #prepend
    >
      <v-app-bar-nav-icon @click="toggleNavigationDrawer()"></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title>
      <router-link :to="{ name: 'HOME' }" class="text-white text-decoration-none">MedDojo</router-link>
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <template v-if="isLoggedIn">
      <p class="header__user-name mr-4">{{ userNameLabel }}</p>

      <localization-menu/>

      <div class="header__separator"/>

      <v-btn class="mr-2"
             variant="text"
             :to="{ name: 'HOME' }"
      >
        {{ $t('header.navigation.home') }}
      </v-btn>

      <v-btn class="mr-2"
             variant="text"
             :to="{ name: 'PROFILE' }"
      >
        {{ $t('header.navigation.profile') }}
      </v-btn>

      <v-btn icon
             @click="signOut"
      >
        <v-icon icon="mdi-logout"/>
      </v-btn>
    </template>

    <template v-else>
      <localization-menu/>

      <v-btn class="mr-2 ml-4"
             variant="text"
             :to="{ name: 'SIGN_IN' }"
      >
        {{ $t("header.unsigned-navigation.sign-in") }}
      </v-btn>

      <v-btn variant="text"
             :to="{ name: 'SIGN_UP' }"
      >
        {{ $t("header.unsigned-navigation.sign-up") }}
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import LocalizationMenu from "@/components/common/TheHeader/LocalizationMenu";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "TheHeader",
  components: {
    LocalizationMenu
  },
  computed: {
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', ['isLoggedIn']),

    userNameLabel() {
      return this.user.displayName || this.user.email
    }
  },
  methods: {
    ...mapActions('Interface', ['toggleNavigationDrawer']),

    signOut() {
      this.$store.dispatch('Auth/signOut')
    }
  }
}
</script>

<style lang="scss">
.header {

  > .v-toolbar__content {
    height: $app-header-height !important;
  }

  &__user-name {
    opacity: .7;
  }

  &__separator {
    width: 2px;
    height: 1.5em;
    background-color: rgba(white, .7);
    margin: 0 1em;
  }
}
</style>