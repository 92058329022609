import { createStore } from "vuex";

import actions from "@/store/actions";

import AiChat from "@/store/modules/AiChat";
import Auth from "@/store/modules/Auth";
import Interface from "@/store/modules/Interface";
import Records from "@/store/modules/Records";
import SearchStates from "@/store/modules/SearchStates";
import Tables from "@/store/modules/Tables";

const store = createStore({
  actions,

  modules: {
    AiChat,
    Auth,
    Interface,
    Records,
    SearchStates,
    Tables
  }
})

export default store