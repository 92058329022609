import AlgoliaClient from "@/plugins/algolia-client";
import Record from "@/models/Record";
import FIELD_TYPES from "@/config/field-types";

/**
 * @typedef SearchState
 *
 * @field {Object} searchQuery
 * @field {String} searchQuery.fieldId
 * @field {String} searchQuery.value
 * @field {Array<Record>} records
 * @field {Number} [documentsCount]
 *
 */

const state = () => ({
  /**
   * Key is a `tableId`. Value is an `SearchState`, which has these fields:
   *
   * @type {Object<String, SearchState>}
   */
  searchStates: {}
})

const getters = {
  getTableSearchState: (state) => (tableId) => state.searchStates[tableId]
}

const mutations = {
  SET_TABLE_SEARCH_STATE(state, { tableId, searchState }) {
    state.searchStates = Object.assign({}, state.searchStates, {
      [tableId]: searchState
    })
  },
  APPEND_TABLE_SEARCH_STATE_RECORDS(state, { tableId, records }) {
    state.searchStates[tableId].records = [
      ...state.searchStates[tableId].records,
      ...records
    ]
  },
  REMOVE_TABLE_SEARCH_STATE(state, { tableId }) {
    if (state.searchStates[tableId]) {
      delete state.searchStates[tableId]
    }
  }
}

const actions = {
  searchTableRecords({ rootGetters, commit }, { tableId, searchQuery, offset }) {
    const table = rootGetters['Tables/getTableById'](tableId)
    const field = table.fields.find(({ id }) => id === searchQuery.fieldId)

    const typoTolerance = field?.type === FIELD_TYPES.STRING

    AlgoliaClient.getTableRecordsByQuery({
      tableId,
      fieldId: searchQuery.fieldId,
      searchQuery: searchQuery.value,
      offset,
      typoTolerance
    })
      .then((data) => {
        const records = data.hits
          .map((hit) => Object.assign(hit, { id: hit.objectID }))
          .map((hit) => new Record(hit))
        const searchState = {
          searchQuery,
          records,
          documentsCount: data.nbHits
        }

        if (offset) {
          commit('APPEND_TABLE_SEARCH_STATE_RECORDS', {
            tableId,
            records
          })
        } else {
          commit('SET_TABLE_SEARCH_STATE', {
            tableId,
            searchState
          })
        }

        return searchState
      })
      .catch((e) => {
        console.log(e)
      })
  },
  loadMoreSearchTableRecords({ getters, dispatch }, { tableId }) {
    const { searchQuery, records } = getters.getTableSearchState(tableId)

    return dispatch('searchTableRecords', {
      tableId,
      searchQuery,
      offset: records?.length || null
    })
  },
  clearTableSearchState({ commit }, { tableId }) {
    commit('REMOVE_TABLE_SEARCH_STATE', { tableId })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}