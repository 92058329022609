import api from "@/api";
import { AI_CHAT_MODEL } from "@/config/openai-chat";

const state = () => ({

})

const getters = {

}

const mutations = {

}

const actions = {
  askChat(_, { messages }) {
    return api.openai.chat({
      model: AI_CHAT_MODEL,
      messages
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}