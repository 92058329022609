import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import colors from "vuetify/lib/util/colors";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.teal.base, // #009688
          secondary: colors.lightBlue.base // #03A9f4
        }
      }
    }
  }
})

export default vuetify
