import ApiClient from "@/api/api-client";

export default {
  /**
   *
   * @param {Object} newRecordData
   * @param {String} newRecordData.tableId
   * @param {Object} newRecordData.values
   *
   * @return {Promise<any>}
   */
  createRecord(newRecordData) {
    return ApiClient.post("/record/create", newRecordData)
  },

  /**
   *
   * @param {Object} payload
   * @param {String} payload.tableId
   * @param {String} payload.recordId
   * @param {Object.<String, any>} payload.values
   *
   * @return {Promise<any>}
   */
  editRecord(payload) {
    return ApiClient.post("/record/change", payload)
  },

  /**
   *
   * @param {Object} recordData
   * @param {String} recordData.tableId
   * @param {String} recordData.recordId
   * @return {Promise<any>}
   */
  deleteRecord(recordData) {
    return ApiClient.post("/record/delete", recordData)
  },

  /**
   *
   * @param {Object} requestPayload
   * @param {String} requestPayload.tableId
   * @param {String} [requestPayload.startAfter]
   * @param {Number} [requestPayload.itemsCount]
   *
   * @return {Promise<any>}
   */
  getTableRecords(requestPayload) {
    return ApiClient.post("/record/getTableRecords", requestPayload)
  },

  /**
   *
   * @param {Object} requestPayload
   * @param {String} requestPayload.tableId
   * @param {String} requestPayload.recordId
   * @return {Promise<any>}
   */
  getRecord(requestPayload) {
    return ApiClient.post("/record/get", requestPayload)
  },

  /**
   *
   * @param {Object} requestPayload
   * @param {String} requestPayload.tableId
   * @param {String} requestPayload.fieldId
   * @param {String} requestPayload.queryValue
   * @param {String} [requestPayload.startAfterId]
   * @param {Number} [requestPayload.itemsCount]
   *
   * @return {Promise<any>}
   */
  getTableRecordsByQuery(requestPayload) {
    return ApiClient.post("/record/getTableRecordsByQuery", requestPayload)
  }
}