import ApiClient from "@/api/api-client";

export default {
  getTable(tableId) {
    return ApiClient.post("/table/get", { tableId })
  },

  getUserTables() {
    return ApiClient.post("/table/getUserTables")
  },

  createTable(newTableData) {
    return ApiClient.post("/table/create", newTableData)
  },

  changeTable(tableData) {
    return ApiClient.post("/table/change", tableData)
  },

  changeTableFieldsOrder(payload) {
    return ApiClient.post("/table/changeFieldsOrder", payload)
  },

  createTableField(payload) {
    return ApiClient.post("/table/createField", payload)
  },

  deleteTable(tableId) {
    return ApiClient.post("/table/delete", { tableId })
  }
}