import auth from "@/plugins/firebase-auth";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updateProfile,
  updateEmail,
  updatePassword
} from "firebase/auth";
import router from "@/router";

const state = () => ({
  user: null,
  isInitialized: false
})

const getters = {
  isLoggedIn: (state) => state.user !== null
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_IS_INITIALIZED_STATUS(state, status) {
    state.isInitialized = status
  }
}

const actions = {
  setUser({ commit, dispatch }, user) {
    commit('SET_USER', user)

    if (user !== null) {
      dispatch('initPrivateData', undefined, { root: true })

      if (router.currentRoute.value.meta.isAuthPage) {
        router.push({ name: 'HOME' })
      }
    } else if (router.currentRoute.value.meta.needAuth) {
      router.push({ name: 'SIGN_IN' })
    }

    commit('SET_IS_INITIALIZED_STATUS', true)
  },
  signUp(_, { email, password }) {
    return createUserWithEmailAndPassword(auth, email, password)
  },
  signIn(_, { email, password }) {
    return signInWithEmailAndPassword(auth, email, password)
  },
  signOut() {
    return signOut(auth)
      .then(() => {
        window.location.reload()
      })
  },
  sendPasswordResetEmail(_, { email }) {
    return sendPasswordResetEmail(auth, email)
  },
  resetPasswordWithCode(_, { password, code }) {
    return confirmPasswordReset(auth, code, password)
  },
  updateProfile({ state }, { displayName }) {
    return updateProfile(state.user,  {
      displayName
    })
  },
  updateEmail({ state }, { email }) {
    return updateEmail(state.user, email)
  },
  updatePassword({ state }, { password }) {
    return updatePassword(state.user, password)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
