import algoliaSearch from "algoliasearch";
import api from "@/api";

import { RECORDS_COUNT } from "@/config/table";

const ALGOLIA_APP_ID = process.env.VUE_APP_ALGOLIA_APP_ID
const ALGOLIA_INDEX_NAME_RECORDS = process.env.VUE_APP_ALGOLIA_INDEX_NAME_RECORDS

class AlgoliaClient {
  constructor() {
    this.tablesClients = {}
  }

  async getTableRecordsByQuery({ tableId, fieldId, searchQuery, offset, typoTolerance }) {
    const tableClient = await this._getTableClient(tableId)
    const index = tableClient.initIndex(ALGOLIA_INDEX_NAME_RECORDS)

    return index.search(searchQuery, {
      filters: "status:active",
      restrictSearchableAttributes: [`values.${ fieldId }`],
      hitsPerPage: RECORDS_COUNT,
      length: offset ? RECORDS_COUNT : undefined,
      offset,
      typoTolerance: typoTolerance || false
    })
  }

  async _getTableClient(tableId) {
    return this.tablesClients[tableId] || await this._createTableClient(tableId)
  }

  async _createTableClient(tableId) {
    const tableApiKey = await this._getTableApiKey(tableId)
    const tableClient =  algoliaSearch(ALGOLIA_APP_ID, tableApiKey)

    this.tablesClients[tableId] = tableClient
    return tableClient
  }

  async _getTableApiKey(tableId){
    const response = await api.algoliaAccess.getTableRecordsApiKey(tableId)
    const key = response?.data?.key

    if (!key) {
      throw {
        message: "No error in get table records api key response",
        data: response
      }
    }

    return key
  }

}

export default new AlgoliaClient()