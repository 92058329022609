<template>
  <v-navigation-drawer v-if="showNavigationDrawer"
                       v-model="isOpen"
  >
    navigation drawer
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "NavigationDrawer",
  computed: {
    ...mapState('Interface', ['isNavigationDrawerOpen']),

    showNavigationDrawer() {
      return !this.$route.meta.isAuthPage
    },
    isOpen: {
      get() {
        return this.$vuetify.display.lgAndUp || this.isNavigationDrawerOpen
      },
      set(isOpen) {
        this.toggleNavigationDrawer(isOpen)
      }
    }
  },
  methods: {
    ...mapActions('Interface', ['toggleNavigationDrawer'])
  }
}
</script>