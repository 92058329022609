<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn icon="mdi-translate"
             v-bind="props"
             variant="tonal"
      />
    </template>

    <v-list>
      <v-list-item v-for="localization in localizations"
                   :key="localization"
                   :active="localization === locale"
                   @click="setLocale(localization)"
      >
        <template #prepend>
          <img :src="getFlagIcon(localization)"
               :alt="`${ localization } flag icon`"
               class="localization-item__flag"
          >
        </template>
        <v-list-item-title>{{ $t(`localizations.${ localization }`) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { LOCALIZATION_FLAGS } from "@/config/localization";
import { setLocalStorageValue } from "@/helpers/localStorage";
import localStorageKeys from "@/config/localStorageKeys";

export default {
  name: "LocalizationMenu",
  computed: {
    locale: {
      get() {
        return this.$i18n.locale
      },
      set(locale) {
        this.$i18n.locale = locale
        setLocalStorageValue(localStorageKeys.LOCALIZATION, locale)
      }
    },
    localizations() {
      return this.$i18n.availableLocales
    }
  },
  methods: {
    setLocale(locale) {
      this.locale = locale
    },
    getFlagIcon(locale) {
      return require(`@/assets/images/icons/flags/${ LOCALIZATION_FLAGS[locale] }.svg`)
    }
  }
}
</script>

<style lang="scss">
.localization-item {
  &__flag {
    width: 25px;
    height: 15px;
    margin-right: 5px;
  }
}
</style>