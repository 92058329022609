import Field from "@/models/Field";

export default class Table {

  constructor(data) {
    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.recordsCount = data.recordsCount
    this.status = data.status
    this.createdAt = new Date(data.createdAt)
    this.creatorUid = data.creatorUid

    this.setFields(data.fields)
  }

  setFields(fields) {
    this.fields = Object.entries(fields)
      .map(([id, fieldData]) => new Field(Object.assign({ id }, fieldData)))
      .sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
  }

}