import store from "@/store";

export default async function authInterceptor(request){

  if (store.getters['Auth/isLoggedIn']) {
    request.headers['authorization'] = await store.state.Auth.user.getIdToken()
  }

  return request

}