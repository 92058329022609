import api from "@/api";
import Table from "@/models/Table";

const state = () => ({
  tables: [],
  isLoading: true
})

const getters = {
  getTableById: (state) => (tableId) => state.tables.find(({ id }) => id === tableId)
}

const mutations = {
  SET_TABLES(state, tables) {
    console.log(tables)
    state.tables = tables
  },
  ADD_TABLE(state, table) {
    state.tables.push(table)
  },
  SET_IS_LOADING_STATE(state, isLoading) {
    state.isLoading = isLoading
  },
  INCREMENT_TABLE_RECORDS_COUNT(state, tableId) {
    let table = state.tables.find(({ id }) => tableId === id)

    if (table) {
      table.recordsCount++
    }
  },
  DECREMENT_TABLE_RECORDS_COUNT(state, tableId) {
    let table = state.tables.find(({ id }) => tableId === id)

    if (table) {
      table.recordsCount--
    }
  },
  EDIT_TABLE_INFO(state, { tableId, name, description }) {
    let table = state.tables.find(({ id }) => tableId === id)

    if (table) {
      table.name = name
      table.description = description
    }
  },
  SET_TABLE_FIELDS(state, { tableId, fields })  {
    let table = state.tables.find(({ id }) => tableId === id)

    if (table) {
      table.setFields(fields)
    }
  },
  REMOVE_TABLE(state, tableId) {
    state.tables = state.tables.filter(({ id }) => id !== tableId)
  }
}

const actions = {
  downloadTables({ commit }) {
    commit('SET_IS_LOADING_STATE', true)
    api.tables.getUserTables()
      .then(({ data }) => {
        commit('SET_TABLES', data.map((tableData) => new Table(tableData)))
        commit('SET_IS_LOADING_STATE', false)
      })
  },
  createTable({ commit }, data) {
    return api.tables.createTable(data)
      .then(({ data }) => {
        let table = new Table(data)
        commit('ADD_TABLE', table)
        return table
      })
  },
  incrementTableRecordsCount({ commit }, tableId) {
    commit('INCREMENT_TABLE_RECORDS_COUNT', tableId)
  },
  decrementTableRecordsCount({ commit }, tableId) {
    commit('DECREMENT_TABLE_RECORDS_COUNT', tableId)
  },
  editTableInfo({ commit }, { tableId, name, description }) {
    return api.tables.changeTable({
      tableId,
      name,
      description
    }).then(({ data }) => {
      commit('EDIT_TABLE_INFO', {
        tableId,
        ...data
      })

      return data
    })
  },
  editTableFieldsOrder({ commit }, { tableId, fields }) {
    return api.tables.changeTableFieldsOrder({
      tableId,
      fields
    }).then(({ data }) => {
      commit('SET_TABLE_FIELDS', {
        tableId,
        fields: data
      })

      return data
    })
  },
  createTableField({ commit }, { tableId, field }) {
    const { name, type, afterField, config } = field

    return api.tables.createTableField({
      tableId,
      name,
      type,
      afterField,
      config
    }).then(({ data }) => {
      commit('SET_TABLE_FIELDS', {
        tableId,
        fields: data
      })

      return data
    })
  },
  deleteTable({ commit }, tableId) {
    return api.tables.deleteTable(tableId)
      .then(({ data }) => {
        commit('REMOVE_TABLE', tableId)

        return data
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
