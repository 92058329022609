const SignIn = () => import("@/views/Auth/SignIn");
const SignUp = () => import("@/views/Auth/SignUp");
const RestorePassword = () => import("@/views/Auth/RestorePassword");
const ResetPassword = () => import("@/views/Auth/ResetPassword");

const Home = () => import("@/views/App/Home");
const Profile = () => import("@/views/App/Profile");
const CreateTable = () => import("@/views/App/CreateTable");
const Table = () => import("@/views/App/Table");
const CreateRecord = () => import("@/views/App/CreateRecord");
const EditRecord = () => import("@/views/App/EditRecord");

const routes = [
  {
    name: "SIGN_IN",
    component: SignIn,
    path: '/sign-in',
    meta: {
      isAuthPage: true
    }
  },
  {
    name: "SIGN_UP",
    component: SignUp,
    path: '/sign-up',
    meta: {
      isAuthPage: true
    }
  },
  {
    name: "RESTORE_PASSWORD",
    component: RestorePassword,
    path: '/restore-password',
    meta: {
      isAuthPage: true
    }
  },
  {
    name: "RESET_PASSWORD",
    component: ResetPassword,
    path: '/reset-password',
    meta: {
      isAuthPage: true
    }
  },

  {
    name: "HOME",
    component: Home,
    path: '/home',
    meta: {
      needAuth: true
    }
  },
  {
    name: "PROFILE",
    component: Profile,
    path: '/profile',
    meta: {
      needAuth: true
    }
  },
  {
    name: "CREATE_TABLE",
    component: CreateTable,
    path: '/create-table',
    meta: {
      needAuth: true
    }
  },
  {
    name: "TABLE",
    component: Table,
    path: '/table/:tableId',
    meta: {
      needAuth: true
    }
  },
  {
    name: "CREATE_RECORD",
    component: CreateRecord,
    path: '/table/:tableId/create-record',
    meta: {
      needAuth: true
    }
  },
  {
    name: "EDIT_RECORD",
    component: EditRecord,
    path: '/table/:tableId/edit-record/:recordId',
    meta: {
      needAuth: true
    }
  },

  {
    path: '/:pathMatch(.*)',
    redirect: '/sign-in'
  }
]

export default routes