import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store";

import routes from "@/router/routes";

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.Auth.isInitialized) {
    next()
  } else if (to.meta.needAuth && !store.getters['Auth/isLoggedIn']) {
    next({ name: 'SIGN_IN' })
  } else if (to.meta.isAuthPage && store.getters['Auth/isLoggedIn']) {
    next({ name: 'HOME' })
  } else {
    next()
  }
})

export default router