<template>
  <div class="app-container">
    <v-app v-if="isAuthInitialized"
           id="app"
    >
      <the-header/>

      <navigation-drawer v-if="false"/>

      <v-main>
        <v-container fluid
                     class="app__content"
        >
          <router-view/>
        </v-container>
      </v-main>
    </v-app>

    <transition name="fade">
      <div v-if="!isAuthInitialized"
           class="auth-loading d-flex justify-center align-center flex-column"
      >
        <p class="text-h4">App is loading. Please, wait...</p>

        <v-progress-circular color="primary"
                             indeterminate
                             :size="75"
                             :width="8"
                             class="mt-8"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import TheHeader from "@/components/common/TheHeader/TheHeader";
import NavigationDrawer from "@/components/common/NavigationDrawer/NavigationDrawer";
import { mapState } from "vuex";

export default {
  name: 'App',
  components: {
    TheHeader,
    NavigationDrawer
  },
  computed: {
    ...mapState('Auth', {
      'isAuthInitialized': 'isInitialized'
    })
  }
}
</script>

<style lang="scss">
.app-container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.app {
  &__content {
    height: 100%;
    padding: $app-content-container-padding !important;
  }
}

.auth-loading {
  position: absolute;
  background-color: $app-background-color;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
}
</style>
